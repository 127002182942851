import React from "react"

import { graphql, StaticQuery } from "gatsby"
import OrderNow from "../components/ordernow"
import Layout from "../components/layout"
import SEO from "../SEO"
import GA from "../components/ga"
import Hero from "../components/hero"
import Newsletter from "../components/newsLetter"
import Sidebar from "../components/sidebar"
// import SidebarCart from "../components/sidebarCart"

// Styles
// import styles from "./scss/services.module.scss"

const services = () => (
  <StaticQuery
    query={graphql`
      {
        contentfulCurbsidePickup {
          titleDesktop
          titleMobile
          hero {
            title
            description
            file {
              url
            }
          }
          body {
            body
          }
        }
      }
    `}
    render={(data) => (
      <>
        <SEO title="Brophy Brothers | Curbside" />
        <GA />
        <Layout>
          {/*  {/* <SidebarCart /> */}
          <Hero
            text=""
            image={data.contentfulCurbsidePickup.hero.file.url}
            buttons={false}
          />
          <OrderNow />
          <Newsletter />
          <Sidebar />
        </Layout>
      </>
    )}
  />
)

export default services
