import React from "react"
import PropTypes from "prop-types"

const InnerWrapper = ({ children, width }) => {
  const style = {
    maxWidth: width + "px",
    margin: "0 auto",
  }
  return <section style={style}>{children}</section>
}

InnerWrapper.propTypes = {
  width: PropTypes.number,
}

InnerWrapper.defaultProps = {
  width: 1024,
}

export default InnerWrapper
