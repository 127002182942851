import React, { useState, useEffect } from "react"
import { graphql, StaticQuery } from "gatsby"
// import { FaChevronRight } from "react-icons/fa"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
// import Pulse from "react-reveal/Pulse"
import _ from "lodash"
// Components
import InnerWrapper from "./innerWrapper"
// import SidebarCart from "./sidebarCart"
// Styles
import styles from "./scss/ordernow.module.scss"

const OrderNow = (props) => {
  // eslint-disable-next-line
  const [text, setText] = useState("")
  // eslint-disable-next-line
  const [item, setItem] = useState("")
  const [cart, setCart] = useState([])
  // eslint-disable-next-line
  const [products, setProducts] = useState([])

  // eslint-disable-next-line
  function addItemToCart(item) {
    if (typeof window !== "undefined") {
      let cart_ = cart
      const exists = cart_.some((el) => el.sku === item.sku)

      if (exists) {
        cart_.filter((el) => {
          if (el.sku === item.sku) {
            el.quantity++
          }
          return cart
        })
      } else {
        cart_.push({
          name: item.name,
          sku: item.sku,
          quantity: 1,
          price: item.price,
        })
      }

      setCart(cart_)

      localStorage.setItem("cart", JSON.stringify(cart_))
      localStorage.setItem(
        "quantity",
        JSON.stringify(_.sumBy(cart_, "quantity"))
      )
      setText("Item Added!")
      setItem(item.name)

      setTimeout(() => {
        setText("")
        setItem("")
      }, 1000)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      let cart = JSON.parse(localStorage.getItem("cart"))
      if (cart !== null) {
        setCart(cart)
      }
      const products = props.data.allStripeSku.edges.map((item) => ({
        name: item.node.product.name,
        sku: item.node.id,
        price: item.node.price / 100,
      }))
      setProducts(products)
      localStorage.setItem("quantity", 0)
    }
  }, [props.data.allStripeSku.edges])
  // eslint-disable-next-line
  const saleItems = props.data.allStripeSku.edges
  const body = props.data.contentfulCurbsidePickup
  return (
    <>
      {/*  {/* <SidebarCart /> */}
      {/* <span id="services" aria-hidden="true">
        <br />
      </span> */}
      <section className={`section ${styles.orderNow}`} id="curbside">
        <InnerWrapper width={1200}>
          <div className="banner">
            <h2 className="ribbon">
              <span className="top">&nbsp;</span>
              <span className="text desktop">{body.titleDesktop}</span>
              <span className="text mobile"> {body.titleMobile}</span>
              <span className="bottom">&nbsp;</span>
            </h2>
          </div>

          {/* {documentToReactComponents(
              data.contentfulServicesSection.body.json
            )} */}

          <p className={styles.description}>
            {documentToReactComponents(body.body.json)}
          </p>
        </InnerWrapper>
      </section>
    </>
  )
}

export default (props) => (
  <StaticQuery
    query={graphql`
      {
        allStripeSku {
          edges {
            node {
              id
              active
              price
              product {
                name
                metadata {
                  description
                  contents
                }
              }
              image
              localFiles {
                extension
              }
            }
          }
        }
        contentfulCurbsidePickup {
          titleDesktop
          titleMobile
          body {
            json
          }
        }
      }
    `}
    render={(data) => <OrderNow data={data} {...props} />}
  />
)
